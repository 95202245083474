import React from "react"
import TabNavigation from "../../components/TabNavigation"
import LayoutBasic from "../../components/LayoutBasic"
import BasicText from "../../components/BasicText"
import Info2column from "../../components/Info2column"
import ModalLink from "../../components/ModalLink"
import ColumnGrid from "../../components/ColumnGrid"
import css from "./stylesheets/basicInformation.module.scss"

import Seo from "../../components/Seo"
import IconInfo from "../../images/icon_info.svg"
import CenterContentLayout from "../../components/CenterContentLayout"

function PageBasicInformation(props) {
  return (
    <div>
      <Seo title={`ABOUT JAPAN | BASIC INFORMATION`} lang={`en`} />

      <LayoutBasic lang={`en`}>
        <CenterContentLayout>
          <BasicText>
            <h1>BASIC INFORMATION</h1>
            <p>
              What’s Japan like? How to get a job in the nursing care field in
              Japan?
              <br />
              Let us introduce the basic information about Japan and four
              different types of status of residence to work as a care worker.
            </p>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <TabNavigation
            links={[
              {
                title: `ABOUT JAPAN`,
                link: `/en/basic-information/`,
                current: true,
              },
              { title: `ABOUT KAIGO`, link: `/en/basic-information/kaigo/` },
            ]}
          />
        </CenterContentLayout>

        <CenterContentLayout>
          <BasicText>
            <h2 className={css.BasicInfoH2}>ABOUT JAPAN</h2>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <Info2column
            id={`section-1`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_01.svg`}
                alt="Total Area"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>Total Area:</dt>
                <dd>
                  378,000km<sup>2</sup>
                </dd>
              </dl>
              Japan is the biggest island in East Asia, that stretches from
              north to south. The total land area is ranked as the 60th in the
              world. It is as big as Vietnam, and Malaysia in Asia, and Germany
              in Europe. There are five major islands: from north, Hokkaido,
              Honshu (the main island), Shikoku, Kyushu and Okinawa. There are
              also over 6,800 small islands.
            </div>
          </Info2column>
          <Info2column
            id={`section-2`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_02.svg`}
                alt="Population"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>Population:</dt>
                <dd>
                  About 126,020,000<sup>※1</sup>
                </dd>
              </dl>
              <dl>
                <dt>Average age:</dt>
                <dd>46.4 Years Old</dd>
              </dl>
              The total population hit its peak in 2008, with 128,080,000, and
              has been declining since then. In 2019, the working age
              population, aged 15-64, was about 60 percent of the population and
              about 28 percent were classified as elderly (over 65). Japan has
              the world’s highest aging rate.
              <sup>※2</sup>
            </div>
          </Info2column>
          <Info2column
            id={`section-3`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_03.svg`}
                alt="Language"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>Language:</dt>
                <dd>Japanese</dd>
              </dl>
              There are three major writing systems in Japanese:
              ‘Kanji,’‘Hiragana’ and ‘Katakana.’ There is also ‘Romaji’ which is
              used to show how the characters written are read. As for spoken
              language, there are numbers of dialects according to areas.
            </div>
          </Info2column>
          <Info2column
            id={`section-4`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_04.svg`}
                alt="Currency"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>Currency:</dt>
                <dd>Yen（￥）</dd>
              </dl>
              Six coins and four notes are used as Japanese currency. The
              exchange rate of the currency is 110 Japanese yen to the US dollar
              (as of February 2020). The stations and shops where you can use
              electronic money are increasing in major cities these days.
            </div>
          </Info2column>
          <Info2column
            id={`section-5`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_05.svg`}
                alt="Religions"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>Religions:</dt>
                <dd>
                  Shinto, Buddhism,
                  <br />
                  Christianity and More
                </dd>
              </dl>
              Japanese people enjoy a variety of religious-oriented cultures
              like Christmas. For example, visiting shrines or temples on New
              Year’s Day is called ‘Hatsumoude.’ In August, there is ‘Obon’
              which honors the spirits of ancestors. There are also many people
              who have no particular thoughts on religion.
            </div>
          </Info2column>
          <Info2column
            id={`section-6`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_06.svg`}
                alt="Politics"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>Politics:</dt>
                <dd>Democracy</dd>
              </dl>
              It is based on three principles of Japan’s Constitution:
              ‘Sovereignty of the People,’ ‘Respect for Fundamental Human
              Rights’ and ‘Pacifism.’ Japan is a democratic country therefore
              the members of the government are chosen by the nations at the
              election.
            </div>
          </Info2column>
          <Info2column
            id={`section-7`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_07.svg`}
                alt="Weather"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>Weather:</dt>
                <dd>Subarctic to Subtropical</dd>
              </dl>
              As Japan stretches long from north to south, the weather differs
              from subarctic to subtropical climates. Most parts of the country
              belong to the temperate zone with four distinctive seasons
              (spring, summer, fall, winter). There are many mountain ranges so
              they cause cloudy, rainy or snowy weather on the Japan Sea side
              and sunny weather on the Pacific Ocean side. The weather is very
              different in each area.
            </div>
          </Info2column>
        </CenterContentLayout>

        <CenterContentLayout>
          <BasicText>
            <hr className={`only-pc`} />
            <h4 style={{ fontSize: `16px` }}>
              The Cities<sup>※3</sup>:
            </h4>
          </BasicText>

          <div className={css.BasicInfoCityGrid}>
            <ColumnGrid column={4} columnSp={2}>
              <ModalLink id={`modal-1`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_01.svg`}
                    alt="Sapporo (Hokkaido Prefecture)"
                    width={280}
                    height={190}
                  />
                  <span>
                    Sapporo (Hokkaido Prefecture)
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>Sapporo (Hokkaido Prefecture)</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_01.svg`}
                      alt="Sapporo (Hokkaido Prefecture)"
                    />
                  </p>
                  <p>
                    The average maximum temperature in August is 26.4°C so the
                    climate is mild with less influence from the rainy season or
                    typhoons. It is classified as one of the coldest areas in
                    Japan. The average minimum temperature gets down to -7°C in
                    January, however, most buildings are equipped with heaters
                    so you will be warm enough when you are inside. There is an
                    occasional heavy snowfall, which accumulates to one meter.
                    You can enjoy skiing and snowboarding in the city of
                    Sapporo. Both agriculture and fishing industries are
                    flourishing, producing a lot of notable food, such as sushi,
                    seafood, Genghis Kahn (grilled mutton), ramen noodles and
                    soup curry.
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-2`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_02.svg`}
                    alt="Sendai (Miyagi Prefecture)"
                    width={280}
                    height={190}
                  />
                  <span>
                    Sendai (Miyagi Prefecture)
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>Sendai (Miyagi Prefecture)</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_02.svg`}
                      alt="Sendai (Miyagi Prefecture)"
                    />
                  </p>
                  <p>
                    Sendai has the top population rate in the Tohoku area. It is
                    about 1.5 hours to Tokyo on the Shinkansen (the bullet
                    train). The average maximum temperature is 27.9℃ in August
                    and the average minimum temperature is -1.7℃ in January.
                    Tohoku is often considered as an area with heavy snow,
                    however, the total snowfall in Sendai is comparatively
                    little so it is rather pleasant throughout the year. The
                    city is close to both the sea and the mountains so food is
                    plentiful. Oysters and sauries are in season from fall to
                    winter. Sasa Kamaboko (fish cakes) and beef tongue are also
                    popular.
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-3`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_03.svg`}
                    alt="Tokyo 23 Wards (Tokyo Prefecture)"
                    width={280}
                    height={190}
                  />
                  <span>
                    Tokyo 23 Wards (Tokyo Prefecture)
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>Tokyo 23 Wards (Tokyo Prefecture)</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_03.svg`}
                      alt="Tokyo 23 Wards (Tokyo Prefecture)"
                    />
                  </p>
                  <p>
                    Tokyo is the biggest city in Japan. In August, the average
                    maximum temperature in the Chiyoda District is 30.8℃ and the
                    average minimum temperature in January is 0.9℃. Located on
                    the Pacific Sea side, Tokyo’s summer is comparatively hot
                    and humid. Typhoons hit the city sometimes so the amount of
                    rainfall is quite high. The famous local foods are Edomae
                    Sushi and Monjayaki (pan-fried batter dish). You can also
                    enjoy a variety of cuisine from all over the world.
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-4`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_04.svg`}
                    alt="Nagoya (Aichi Prefecture)"
                    width={280}
                    height={190}
                  />
                  <span>
                    Nagoya (Aichi Prefecture)
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>Nagoya (Aichi Prefecture)</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_04.svg`}
                      alt="Nagoya (Aichi Prefecture)"
                    />
                  </p>
                  <p>
                    Located at the center of the main island, Nagoya has an
                    average and pleasant climate. In August, its average maximum
                    temperature is 32.8℃. As it is on the Pacific Sea side, it
                    rains a lot during the month of June, July and September,
                    and is affected by typhoons. The average minimum temperature
                    in January is 0.8℃ and there is not much rain. Aichi has a
                    lot of miso related food, such as Miso Katsu (deep-fried
                    pork topped with miso sauce) and Misonikomi Udon (udon
                    simmered with miso). Kishimen (flat noodles) and Ogura Toast
                    (a piece of bread with red bean paste) are also popular.
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-5`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_05.svg`}
                    alt="Osaka (Osaka Prefecture)"
                    width={280}
                    height={190}
                  />
                  <span>
                    Osaka (Osaka Prefecture)
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>Osaka (Osaka Prefecture)</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_05.svg`}
                      alt="Osaka (Osaka Prefecture)"
                    />
                  </p>
                  <p>
                    Osaka is the second-largest city in Japan. It is adjacent to
                    Kyoto, where there are many world heritage sites, and Kobe
                    the harbor city. Facing the Seto Inland Sea, the climate is
                    moderate. Osaka is classified as a hot area as the average
                    maximum temperature in August is 33.4℃. The average minimum
                    temperature in January is 2.8℃. There are many unique foods
                    in Osaka such as Takoyaki (octopus ball), Okonomiyaki
                    (cabbage pancake) and Kushi Katsu (deep-fried meat and
                    vegetables on skewers). Osaka is also renowned for its
                    comedy culture and sometimes called the ‘holy place for
                    comedy.’
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-6`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_06.svg`}
                    alt="Hiroshima (Hiroshima Prefecture)"
                    width={280}
                    height={190}
                  />
                  <span>
                    Hiroshima (Hiroshima Prefecture)
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>Hiroshima (Hiroshima Prefecture)</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_06.svg`}
                      alt="Hiroshima (Hiroshima Prefecture)"
                    />
                  </p>
                  <p>
                    The Hiroshima area facing the Seto Inland Sea is surrounded
                    by mountains, therefore, the climate is stable with less
                    rain throughout the year. The average maximum temperature in
                    August is 32.5℃ and the average minimum temperature in
                    January is 1.7℃. Hiroshima produces the most oysters in
                    Japan. Okonomiyaki, which is different from the one eaten in
                    Osaka, is also popular so you will spot a lot of Okonomiyaki
                    restaurants in the city. Hiroshima has been appealing for
                    peace to the world. It is represented by the Hiroshima Peace
                    Memorial Park and the Atomic Bomb Dome, which is designated
                    as a World Heritage site, both located at the center of the
                    city.
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-7`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_07.svg`}
                    alt="Fukuoka (Fukuoka Prefecture)"
                    width={280}
                    height={190}
                  />
                  <span>
                    Fukuoka (Fukuoka Prefecture)
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>Fukuoka (Fukuoka Prefecture)</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_07.svg`}
                      alt="Fukuoka (Fukuoka Prefecture)"
                    />
                  </p>
                  <p>
                    Fukuoka is the biggest city in the Kyushu area. The city is
                    one of the closest cities to other Asian countries so it
                    encourages cultural exchange through events and facilities,
                    such as Focus on Asia Fukuoka International Film Festival
                    and Fukuoka Asia Art Museum. The average maximum temperature
                    in August is 32.1℃ and the average minimum temperature in
                    January is 3.5℃. It is often hit by typhoons from summer to
                    fall but the climate is mild throughout the year. You can
                    enjoy a variety of local foods, such as Motsu Nabe (offal
                    hot pot), Hakata ramen noodles, sushi, gyoza (dumplings),
                    mentaiko (spicy cod roe) and udon noodles.
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-8`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_08.svg`}
                    alt="Naha City (Okinawa Prefecture)"
                    width={280}
                    height={190}
                  />
                  <span>
                    Naha City (Okinawa Prefecture)
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>Naha City (Okinawa Prefecture)</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_08.svg`}
                      alt="Naha City (Okinawa Prefecture)"
                    />
                  </p>
                  <p>
                    Naha is the city on the main island of Okinawa surrounded by
                    beautiful oceans. Throughout the year, its temperature is
                    quite high so it has a lot of rainfall. The average maximum
                    temperature in August is 31.5℃ and the average minimum
                    temperature is 14.6℃. It is warmer than the other areas of
                    Japan during winter so there is no snowfall. Okinawa is a
                    popular tourist place for its beaches, coral reefs and
                    historical remains from the Ryukyu Kingdom era. Okinawa
                    cuisine is often represented by Okinawa soba and other home
                    cooking of Okinawa which is very different from other parts
                    of Japan. There are also beef steak, and Taco Rice
                    (Taco-flavored minced meat served on rice), which are the
                    products of American culture.
                  </p>
                </div>
              </ModalLink>
            </ColumnGrid>
          </div>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <Info2column
            id={`section-9`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_people.svg`}
                alt="International Residents"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <h3>International Residents</h3>
              <dl>
                <dt>International Residents:</dt>
                <dd>
                  2,829,416
                  <br />
                  <small>（Male: 1,387,401 / Female: 1,442,015）</small>
                  <sup>※4</sup>
                </dd>
              </dl>
              <dl>
                <dt>International Workers:</dt>
                <dd>
                  1,460,463
                  <br />
                  <small>
                    （In the Field of Medical Care and Welfare: 26,086）
                  </small>
                  <sup>※5</sup>
                </dd>
              </dl>
              The number of international residents and workers is increasing
              every year. Half of them are from China, Vietnam and the
              Philippines. Most of the workers are engaged in manufacturing, and
              those who work in the medical and welfare field are still 1.8% of
              all the working population.
            </div>
          </Info2column>
        </CenterContentLayout>

        <CenterContentLayout>
          <BasicText>
            <ul className={`notes`}>
              <li>
                <i>※1</i>
                <span>
                  ‘Statistics’ from the Statistics Bureau of Japan (As of
                  January 1st, 2020)
                </span>
              </li>
              <li>
                <i>※2</i>
                <span>
                  ‘Annual Report on the Aging Society’ (2019) by the Japan
                  Cabinet Office, ‘Databook of International Labor Statistics
                  2018’ by The Japan Institute for Labor Policy and Training
                </span>
              </li>
              <li>
                <i>※3</i>
                <span>
                  The temperatures cited above are based on the average data
                  recorded on ‘Historical Weather Data Search’ by the Japan
                  Meteorological Agency from 1981 until 2010.
                </span>
              </li>
              <li>
                <i>※4</i>
                <span>
                  ‘Press Release: The Total Number of Foreign Residents’ (2019)
                  by the Japan Ministry of Justice Immigration Service Agency
                </span>
              </li>
              <li>
                <i>※5</i>
                <span>
                  ‘Status of Reporting on the Employment of Foreign Workers by
                  Employers’ (October 2018) by the Japan Ministry of Health,
                  Labor and Welfare
                </span>
              </li>
            </ul>
          </BasicText>
        </CenterContentLayout>
      </LayoutBasic>
    </div>
  )
}
export default PageBasicInformation
